.header {
    margin-top: var(--space-md);
    display: grid;
    grid-template-columns: 1fr 68px 1fr;
}
.header a {
    color: var(--color-black);
    text-decoration: none;
}

.right, .menu, .menu ul {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.menu {
    margin: var(--space-md) 0 var(--space-md);
    grid-column: span 3;
}

.menu, .menu ul {
    justify-content: center;
    
}
.right {
    margin-left: 1rem;
}

.menu ul {
    margin: 0; padding: 0;
    list-style-type: none;
}

.menu ul li:not(:last-child) {
    margin-right: var(--space-xs); 
}

.menu ul li a {
    font-weight: 700;
    color: var(--color-gray-10);
    text-decoration: none;
}
.menu ul li a:hover { 
    color: var(--color-primary);
}
